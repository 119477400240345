<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
    >
      إضافة طبيب
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="11">
            <b-card class="iq-border-radius-10 mb-4">
              <b-row class="justify-content-between mb-5">
                <b-col lg="8">
                  <b-row>
                    <b-col md="6" class="mb-3">
                      <input-form
                        placeholder="الاسم بالكامل"
                        label="الإسم بالكامل"
                        validate="required"
                        name="full-name"
                        v-model="doctor.full_name"
                        id="full_name"
                      />
                    </b-col>
                    <b-col md="6" class="mb-3">
                      <country-code
                        placeholder="رقم الهاتف"
                        label="رقم الهاتف"
                        name="phone"
                        v-model="doctor.primary_phone_number"
                        id="phone-code-primary_phone_number"
                        :validate="{ required: true, regex: /^\d{9,11}$/ }"
                        @onSelect="onSelect"
                      />
                    </b-col>
                    <b-col md="6" class="mb-3">
                      <input-form
                        placeholder="البريد الالكترونى"
                        validate="required"
                        label="البريد الالكترونى"
                        name="email"
                        v-model="doctor.email"
                        id="email"
                      />
                    </b-col>
                    <b-col md="6" class="mb-3">
                      <country-code
                        placeholder="رقم هاتف اخر"
                        label="رقم هاتف اخر"
                        name="phone2"
                        v-model="doctor.secondary_phone_number"
                        id="phone-code-secondary_phone_number"
                        :validate="{ required: false, regex: /^\d{9,11}$/ }"
                        @onSelect="onSelect"
                      />
                    </b-col>
                    <b-col md="12" class="mb-3">
                      <input-form
                        placeholder="رقم الهوية"
                        label="رقم الهوية"
                        name="national_id"
                        v-model="doctor.national_id"
                        id="national_id"
                        :validate="{ required: true, numeric: true, digits: 10, regex: /^[1-2][0-9]*$/ }"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <!-- الخدمات -->
            <b-card class="iq-border-radius-10 mb-4">
              <template v-slot:header>
                <h5 class="text-primary">
                  قم بتحديد الخدمات التى تقوم بتقديمها
                </h5>
              </template>
              <div class="dashboard-custom-checkbox">
                <b-form-checkbox-group
                  v-model="doctorServiceSelected"
                  :options="doctorServiceOptions"
                  class="dashboard-custom-checkbox-inner d-flex align-items-center flex-wrap"
                  value-field="item"
                  text-field="name"
                />
              </div>
            </b-card>
            <!-- الاسعار -->
            <b-card class="iq-border-radius-10 mb-4">
              <template v-slot:header>
                <h5 class="text-primary">الأسعار</h5>
              </template>
              <b-row class="" v-if="doctorServiceSelected.length">
                <b-col
                  sm="12"
                  v-for="(
                    doctorServiceSelected, index
                  ) in doctorServiceSelected"
                  :key="index"
                >
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                    v-if="doctorServiceSelected.allow_clinic_examine"
                  >
                    <h6 class="setting-box-title font-size-20">كشف العيادة</h6>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="prices.clinic_examine"
                        :validate="{ required: true, numeric: true, regex: /^[1-9][0-9]*$/ }"
                        name="clinic_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                    v-if="doctorServiceSelected.allow_outdoor_examine"
                  >
                    <h6 class="setting-box-title font-size-20">زيارة منزلية</h6>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="prices.outdoor_examine"
                        :validate="{ required: true, numeric: true, regex: /^[1-9][0-9]*$/ }"
                        name="outdoor_examine"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                  <div
                    class="setting-box-inner d-flex align-items-center gap_2"
                    v-if="doctorServiceSelected.allow_consulting"
                  >
                    <h6 class="setting-box-title font-size-20">استشارة</h6>
                    <div class="d-flex align-items-center gap_1">
                      <input-form
                        class="text-center m-0 matone-value"
                        placeholder=""
                        v-model="prices.consulting"
                        :validate="{ required: true, numeric: true, regex: /^[1-9][0-9]*$/ }"
                        name="consulting"
                      />
                      <span class="small-text">ريال سعودى</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="iq-border-radius-10 mb-4">
              <template v-slot:header>
                <div class="d-flex align-items-center flex-wrap gap_2">
                  <h5 class="text-primary">طريقة الكشف</h5>
                  <div
                    class="d-flex align-items-center justify-content-between flex-wrap gap_1"
                  >
                    <b-form-radio-group
                      id="etectionMethod"
                      v-model="selectedDetectionMethod"
                      :options="optionsDetectionMethod"
                      aria-describedby="ariaDescribedby"
                      name="detectionMethod"
                      class="detection-custom-radio d-flex align-items-center"
                    />
                    <div
                      class="d-flex align-items-center gap_2 setting-box-inp-detection-time"
                      v-if="selectedDetectionMethod === 'appointment'"
                    >
                      <h6
                        class="setting-box-title setting-box-title-detection-time font-size-16"
                      >
                        مدة الكشف
                      </h6>
                      <div class="d-flex align-items-center gap_2">
                        <div class="d-flex align-items-center gap_1">
                          <input-form
                            class="text-center m-0 matone-value"
                            placeholder="1"
                            v-model="examination_duration.hours"
                            @input="validateHours"
                            name="hours"
                          />
                          <span class="small-text">ساعة</span>
                        </div>
                        <div class="d-flex align-items-center gap_1">
                          <input-form
                            class="text-center m-0 matone-value"
                            placeholder="30"
                            v-model="examination_duration.minutes"
                            @input="validateMinutes"
                            name="minutes"
                          />
                          <span class="small-text">دقيقة</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="">
                <h5 class="text-primary">مواعيد العمل</h5>
                <div class="p-4">
                  <div
                    class="d-flex align-items-center gap_1 time-of-work-item flex-wrap"
                    v-for="(timeOfWork, index) in work_times"
                    :key="index"
                  >
                    <div
                      class="time-of-work-item-controls d-flex align-items-center gap_1"
                    >
                      <span
                        class="time-of-work-item-controls-label font-size-20"
                        >{{ timeOfWork.day }}</span
                      >
                      <b-form-checkbox
                        :id="`time-of-work-item-controls-checkbox-${index}`"
                        v-model="timeOfWork.is_active"
                        :name="`time-of-work-item-controls-checkbox-${index}`"
                        class="d-flex align-items-center dashboard-custom-checkbox time-of-work-item-controls-checkbox"
                        :value="false"
                        :unchecked-value="true"
                        ariaDescribedby="item-checkbox-day"
                      >
                        مغلق
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="timeOfWork.is_active"
                      class="d-flex flex-column gap_1"
                    >
                      <div
                        class="d-flex align-items-baseline gap_4 flex-wrap"
                        v-for="(timeItem, idx) in timeOfWork.working_periods"
                        :key="idx"
                      >
                        <div class="d-flex flex-column align-items-start mb-2">
                          <div class="d-flex align-items-center">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              من
                            </h6>
                            <flat-pickr
                              v-model="timeItem.from"
                              :config="config"
                              class="form-control time-of-work-item-controls-date mx-2"
                              :class="{ 'is-invalid': timeItem.error }"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date"
                              @input="onTimeChange(index, idx, timeItem.from)"
                            />
                          </div>
                          <div
                            v-if="timeItem.error"
                            class="invalid-feedback d-block"
                          >
                            يجب أن تبدأ فترة العمل التالية بعد نهاية الفترة
                            السابقة.
                          </div>
                        </div>
                        <div class="d-flex flex-column align-items-start mb-2">
                          <div class="d-flex align-items-center">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              إلى
                            </h6>
                            <flat-pickr
                              v-model="timeItem.to"
                              :config="config2"
                              class="form-control time-of-work-item-controls-date mx-2"
                              :class="{
                                'is-invalid':
                                  hasTimeOverlap(index, idx) ||
                                  !isValidTimeOrder(index, idx),
                              }"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date-to...."
                              @input="onTimeChange(index, idx, timeItem.to)"
                            />
                          </div>

                          <div
                            v-if="
                              hasTimeOverlap(index, idx) ||
                              !isValidTimeOrder(index, idx)
                            "
                            class="invalid-feedback d-block"
                          >
                            <template v-if="hasTimeOverlap(index, idx)">
                              يجب أن تبدأ فترة العمل التالية بعد نهاية الفترة
                              السابقة.
                            </template>
                            <template v-else-if="!isValidTimeOrder(index, idx)">
                              يجب أن يكون وقت النهاية بعد وقت البدء في نفس فترة
                              العمل.
                            </template>
                          </div>
                        </div>
                        <b-button
                          v-if="idx === timeOfWork.working_periods.length - 1"
                          class="d-flex align-items-center bg-transparent border-0 p-0 time-of-work-item-add-btn"
                          @click="addTimeOfWork(index)"
                        >
                          <i class="las la-clock icon"></i>
                          <span>اضافة فترة عمل اخرى</span>
                        </b-button>
                        <b-button
                          v-if="idx > 0"
                          variant="danger"
                          class="d-flex align-items-center bg-transparent border-0 p-0 time-of-work-item-add-btn"
                          @click="deleteTimeOfWork(index, idx)"
                        >
                          <i class="las la-trash icon"></i>
                        </b-button>
                      </div>
                      <div
                        v-if="
                          selectedDetectionMethod === 'first-come-first-served'
                        "
                      >
                        <h6 class="setting-box-title">الحد الأقصى للحجوزات</h6>
                        <input-form
                          class="text-center m-0 matone-value w-50"
                          :placeholder="'عدد الحجوزات'"
                          v-model="max_examinations_count[index]"
                          validate="required|numeric"
                          name="max_examinations_Count"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
            <!-- upload docs -->
            <b-card class="iq-border-radius-10 mb-4">
              <b-row>
                <b-col
                  :md="index === 0 ? 3 : 4"
                  class="mb-4"
                  v-for="(itemFile, index) in requiredDocuments"
                  :key="index"
                >
                  <img-upload-box
                    :data="itemFile"
                    :index="index"
                    @uploadDocument="uploadDocument"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center mt-5">
          <b-button
            variant="primary"
            class="vete-save-btn m-auto"
            type="submit"
            v-if="!loadingButtonSubmit"
          >
            حفظ
          </b-button>
          <b-button variant="primary" class="vete-save-btn m-auto" v-else>
            <spinner-loading :text="'يتم التحميل'" />
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import doctorApi from "../services/doctors"; // Corrected API path
import imgUploadBox from "../components/imgUploadBox";

export default {
  components: { imgUploadBox },
  data() {
    return {
      examination_duration: {
        hours: "1", // Initial hours
        minutes: "30", // Initial minutes
        seconds: "00",
      },
      work_times: [
        {
          day: "Saturday",
          is_active: false, // Set to false for initial active period
          working_periods: [
            { from: "01:00", to: "02:00" }, // Example initial working period
          ],
        },
        {
          day: "Sunday",
          is_active: false,
          working_periods: [{ from: "01:00", to: "02:00" }],
        },
        {
          day: "Monday",
          is_active: false,
          working_periods: [{ from: "01:00", to: "02:00" }],
        },
        {
          day: "Tuesday",
          is_active: false,
          working_periods: [{ from: "01:00", to: "02:00" }],
        },
        {
          day: "Wednesday",
          is_active: false,
          working_periods: [{ from: "01:00", to: "02:00" }],
        },
        {
          day: "Thursday",
          is_active: false,
          working_periods: [{ from: "01:00", to: "02:00" }],
        },
        {
          day: "Friday",
          is_active: false, // Example: Friday is initially inactive
          working_periods: [{ from: "", to: "" }],
        },
      ],
      userData: { name: "anwar" },
      doctorServiceSelected: {
        allow_clinic_examine: false,
        allow_outdoor_examine: false,
        allow_consulting: false, // Added for completeness
      },
      doctorServiceOptions: [
        { value: "allow_clinic_examine", name: "كشف عيادة" },
        { value: "allow_outdoor_examine", name: "زيارة منزلية" },
        { value: "allow_consulting", name: "استشارة" }, // Added for completeness
      ],
      loadingButtonSubmit: false,
      doctorServiceSelected: [],
      doctorServiceOptions: [
        { item: { allow_clinic_examine: true }, name: "كشف عيادة" },
        { item: { allow_outdoor_examine: true }, name: "زيارة منزلية" },
      ],
      prices: {
        clinic_examine: "",
        consulting: "",
        outdoor_examine: "",
      },
      selectedDetectionMethod: "",
      optionsDetectionMethod: [
        { text: "بأولوية الحضور", value: "first-come-first-served" },
        { text: "ميعاد محدد", value: "appointment" },
      ],
      doctor: {
        full_name: "",
        primary_phone_number: "",
        secondary_phone_number: "",
        email: "",
        national_id: "",
      },
      requiredDocuments: [],
      uploadedDcouments: [],
      code: "",
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      config2: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      max_examinations_count: [],
    };
  },
  methods: {
    onSelect(data) {
      this.code = data;
    },
    validateHours(value) {
      const numValue = Number(value);
      if (numValue >= 0 && numValue <= 23 && Number.isInteger(numValue)) {
        this.examination_duration.hours = value;
      }
    },
    validateMinutes(value) {
      const numValue = Number(value);
      if (numValue >= 0 && numValue < 60 && Number.isInteger(numValue)) {
        this.examination_duration.minutes = value;
      }
    },
    deleteTimeOfWork(workTimeIndex, timeItemIndex) {
      this.work_times[workTimeIndex].working_periods.splice(timeItemIndex, 1);
      if (this.work_times[workTimeIndex].working_periods.length === 0) {
        this.work_times.splice(workTimeIndex, 1);
      }
    },
    validateWorkingPeriods(workTimeIndex) {
      const workingPeriods = this.work_times[workTimeIndex].working_periods;
      for (let i = 1; i < workingPeriods.length; i++) {
        const previousEndTime = new Date(
          `2000-01-01 ${workingPeriods[i - 1].to}`
        ).getTime();
        const currentStartTime = new Date(
          `2000-01-01 ${workingPeriods[i].from}`
        ).getTime();
        if (currentStartTime <= previousEndTime) {
          return false;
        }
      }
      return true;
    },
    addTimeOfWork(idx) {
      this.work_times[idx].working_periods.push({ from: "02:30", to: "03:30" });
      this.max_examinations_count.splice(idx + 1, 0);
    },
    getRequiredDocuments() {
      doctorApi.getRequiredDocuments().then((response) => {
        this.requiredDocuments = response.data.data;
        return this.requiredDocuments.reverse();
      });
    },
    onSubmit() {
      this.loadingButtonSubmit = true;
      const doctorServices = this.doctorServiceSelected;
      this.doctorServiceSelected.forEach((item) => {
        const key = Object.keys(item)[0];
        doctorServices[key] = true;
      });
      const selectedPrices = {};
      if (doctorServices.allow_clinic_examine) {
        selectedPrices.clinic_examine = this.prices.clinic_examine;
      }
      if (doctorServices.allow_outdoor_examine) {
        selectedPrices.outdoor_examine = this.prices.outdoor_examine;
      }
      if (doctorServices.allow_consulting) {
        selectedPrices.consulting = this.prices.consulting;
      }

      // Handle work times
      const workOfTimes = this.work_times.map((item, index) => {
        return {
          ...item,
          max_appointments:
            this.selectedDetectionMethod === "first-come-first-served"
              ? this.max_examinations_count[index]
              : null,
          working_periods: item.is_active ? item.working_periods : [],
        };
      });

      let isValid = true;
      for (let i = 0; i < this.work_times.length; i++) {
        if (!this.validateWorkingPeriods(i)) {
          isValid = false;
          core.showSnackbar(
            "error",
            "يجب أن تبدأ فترة العمل التالية بعد نهاية الفترة السابقة."
          );
        }
        if (!this.work_times[i].is_active) continue;
        for (let j = 0; j < this.work_times[i].working_periods.length; j++) {
          if (!this.isValidTimeOrder(i, j)) {
            isValid = false;
            core.showSnackbar(
              "error",
              "يجب أن يكون وقت النهاية بعد وقت البدء في نفس فترة العمل."
            );
          }
        }
      }

      if (!isValid) {
        this.loadingButtonSubmit = false;
        return;
      }

      const payload = {
        doctor_data: {
          ...this.doctor,
          country_code: this.code,
          prices: selectedPrices,
          examine_method: this.selectedDetectionMethod,
          work_times: workOfTimes,
          allow_clinic_examine: doctorServices.allow_clinic_examine,
          allow_outdoor_examine: doctorServices.allow_outdoor_examine,
          allow_consulting: doctorServices.allow_consulting,
        },
        doctor_documents: this.uploadedDcouments,
      };

      if (this.selectedDetectionMethod !== "first-come-first-served") {
        payload.doctor_data.examination_duration = `${this.examination_duration.hours}:${this.examination_duration.minutes}:${this.examination_duration.seconds}`;
      }

      if (this.uploadedDcouments.length === this.requiredDocuments.length) {
        doctorApi
          .addDoctor(payload)
          .then((response) => {
            core.showSnackbar("success", "Doctor added successfully");
            this.$router.push({ name: "hospitalDoctors" });
          })
          .catch((error) => {
            console.error("Error adding doctor:", error);
            core.showSnackbar(
              "error",
              error.message
            );
          })
          .finally(() => {
            this.loadingButtonSubmit = false;
          });
      } else {
        core.showSnackbar("error", "Please upload all required documents.");
      }
    },
    onTimeChange(workTimeIndex, timeItemIndex, newValue, oldValue) {
      if (
        newValue !== oldValue &&
        this.work_times[workTimeIndex].working_periods.length > 1
      ) {
        if (
          !this.validateWorkingPeriods(workTimeIndex) &&
          this.work_times[workTimeIndex].is_active
        ) {
          this.work_times[workTimeIndex].working_periods[
            timeItemIndex
          ].error = true;
        } else {
          this.work_times[workTimeIndex].working_periods[
            timeItemIndex
          ].error = false;
        }
      }
    },
    hasTimeOverlap(workTimeIndex, timeItemIndex) {
      if (this.work_times[workTimeIndex].working_periods.length < 2)
        return false;
      const workingPeriods = this.work_times[workTimeIndex].working_periods;
      const currentPeriod = workingPeriods[timeItemIndex];
      for (let i = 0; i < workingPeriods.length; i++) {
        if (i === timeItemIndex) continue;
        const otherPeriod = workingPeriods[i];
        const currentStartTime = new Date(
          `2000-01-01 ${currentPeriod.from}`
        ).getTime();
        const currentEndTime = new Date(
          `2000-01-01 ${currentPeriod.to}`
        ).getTime();
        const otherStartTime = new Date(
          `2000-01-01 ${otherPeriod.from}`
        ).getTime();
        const otherEndTime = new Date(`2000-01-01 ${otherPeriod.to}`).getTime();
        if (
          (currentStartTime >= otherStartTime &&
            currentStartTime < otherEndTime) ||
          (currentEndTime > otherStartTime && currentEndTime <= otherEndTime) ||
          (currentStartTime < otherStartTime && currentEndTime > otherEndTime)
        ) {
          return true;
        }
      }
      return false;
    },
    isValidTimeOrder(workTimeIndex, timeItemIndex) {
      const workingPeriods = this.work_times[workTimeIndex].working_periods;
      const currentPeriod = workingPeriods[timeItemIndex];
      const fromTime = new Date(`2000-01-01 ${currentPeriod.from}`).getTime();
      const toTime = new Date(`2000-01-01 ${currentPeriod.to}`).getTime();
      return toTime > fromTime;
    },
    uploadDocument(file) {
      this.uploadedDcouments.push(file);
    },
    isValidHour(value) {
      const numValue = Number(value);
      return numValue >= 0 && numValue <= 23 && Number.isInteger(numValue);
    },
    isValidMinute(value) {
      const numValue = Number(value);
      return numValue >= 0 && numValue < 60 && Number.isInteger(numValue);
    },
  },
  watch: {
    userData(val) {
      if (val) {
        this.doctorServiceSelected = [
          { allow_clinic_examine: Boolean(val.allow_clinic_examine) },
          { allow_outdoor_examine: Boolean(val.allow_outdoor_examine) },
          { allow_consulting: Boolean(val.allow_consulting) },
        ];
        // examination method
        this.selectedDetectionMethod = val.examine_method;
        // examination duration
        const splitDuration = val.examination_duration.split(":");
        this.examination_duration = {
          hours: splitDuration[0],
          minutes: splitDuration[1],
          seconds: "00",
        };

        // work times
        if (val.work_times) {
          const newItems = val.work_times.map((item) => {
            if (!item.is_active) {
              if (item.working_periods.length) {
                return item;
              } else {
                return {
                  ...item,
                  working_periods: [{ to: "02:00", from: "01:00" }],
                };
              }
            } else {
              return item;
            }
          });
          this.work_times = newItems;
        } else {
          this.work_times = [
            {
              day: "Saturday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Sunday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Monday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Tuesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Wednesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Thursday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Friday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
          ];
        }

        // prices
        this.prices = {
          clinic_examine:
            val.prices && val.allow_clinic_examine
              ? val.prices.clinic_examine
              : "",
          consulting:
            val.prices && val.allow_consulting ? val.prices.consulting : "",
          outdoor_examine:
            val.prices && val.allow_outdoor_examine
              ? val.prices.outdoor_examine
              : "",
        };
      } else {
        this.doctorServiceSelected = [
          { allow_clinic_examine: false },
          { allow_outdoor_examine: false },
          { allow_consulting: false },
        ];
      }
      this.setSelectedCityFromUserData();
    },
    work_times(newVal, oldVal) {
      // Keep max_examinations_count synced with work_times
      if (newVal.length !== oldVal.length) {
        this.max_examinations_count = newVal.map(
          (item) => item.max_appointments || ""
        );
      } else {
        // Update existing values if work_times changes
        newVal.forEach((item, index) => {
          if (item.max_appointments !== this.max_examinations_count[index]) {
            this.max_examinations_count[index] = item.max_appointments || "";
          }
        });
      }
    },
  },
  created() {
    this.getRequiredDocuments();
  },
  computed: {
    minutesValidation() {
      return this.examination_duration.hours === 0
        ? "required|numeric|min_value:5|max_value:59"
        : "numeric|max_value:59";
    },
    showPriceInputs() {
      return this.doctorServiceSelected.some(
        (service) =>
          service.item.allow_clinic_examine ||
          service.item.allow_outdoor_examine ||
          service.item.allow_consulting
      );
    },
  },
};
</script>
<style scoped>
.position-relative .mb-3.joining-label ::placeholder {
  font-size: 10px;
  font-weight: normal;
}

.position-relative .customize-height .vs__dropdown-menu {
  max-height: 108px !important;
  overflow-y: auto;
}
.position-relative .customize-height .vs__dropdown-toggle {
  border-radius: 10px !important;
}
</style>
